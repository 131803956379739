import nusaJson from "../truffle_abis/NusaToken.json";

// const netID = Object.keys(nusaJson.networks)[0];
const chainID = 97;
const address = '0x05691AfCfa57f02343956707765B6DCbd4594B09';//nusaJson.networks[netID].address;
const abi = nusaJson.abi;
const newChain = [{
    chainId: `0x${Number(chainID).toString(16)}`,
    chainName: 'BSC - testnet',
    nativeCurrency: {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com']
}];
//'https://rinkeby.infura.io/v3/';
// bsc test net
// net ID : 97
// RPC : https://data-seed-prebsc-1-s1.binance.org:8545/

const nusa = {
    abi: abi,
    address: address,
    chainID: chainID,
    newChain: newChain,
};

export default nusa;