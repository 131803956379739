import React, { Component } from 'react';

class Navbar extends Component {
    _connectWalet(evt) {
        evt.preventDefault();
        if (window.ethereum) {
           this.props.connectWalet();            
        } else {
            alert('Wallet Metamask Tidak Terdeteksi')
        }
    }
    render() {
        return (
            <nav className='navbar'>
                <h3>Testing Staking</h3>
                <button className='btn btn-md btn-primary' onClick={evt => this._connectWalet(evt)}>Connect Wallet</button>
                <ul>
                    <li>
                        <small>Account Active :
                            <br />
                            <b><label>{this.props.akun}</label></b>
                        </small>
                    </li>
                    <li>
                        <small>Owner:
                            <br />
                            <b><label>{this.props.akunOwner}</label></b>
                        </small>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Navbar;