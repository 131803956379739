import React, { Component } from 'react';
import AutoNumeric from 'autonumeric';

let anElement = null;
class Main extends Component {
    constructor() {
        super();
        this.state = {
            lblLoading: "-",
            lblLoadingAll: '-'
        };
        this.onInputchange = this.onInputchange.bind(this);
        // this.onSubmitForm = this.onSubmitForm.bind(this);
    }
    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    componentDidMount() {
        // console.log('ON LOAD', document.readyState);
        if (document.readyState == 'complete' && anElement === null) {
            const elm = document.getElementsByClassName("autoNumber");
            for (let i = 0; i < elm.length; i++) {
                const element = elm[i];
                anElement = new AutoNumeric(element, {
                    minimumValue: 0,
                    unformatOnSubmit: true,
                    maximumValue: element.getAttribute("max"),
                    allowDecimalPadding: 'floats',
                    decimalPlaces: 4,
                });
            }
        }
    }
    resetLoadingAll() {
        setTimeout(() => {
            this.setState({ lblLoadingAll: "..." });
        }, 4000);
    }
    updateAutoNumeric(event) {
        const elmJml = AutoNumeric.getAutoNumericElement(event.target)
        elmJml.update({
            maximumValue: event.target.max
        });
    }

    _transferToken = async (evt) => {
        evt.preventDefault();
        const addr = this.state.valAddress;
        const jml = this.valTokenTrf.value.toString();
        // console.log(addr);
        if (this.props.akunOwner == addr) {
            alert('Addres harus selain owner');
            return false;
        }
        this.setState({ lblLoading: "Transaction in progress" });
        const resp = await this.props.trfTokenToCust(addr, jml);
        this.setState({
            lblLoading: "Transfer Token " + resp.txt,
            valAddress: ""
        });
        const elmJml = AutoNumeric.getAutoNumericElement(this.valTokenTrf)
        elmJml.set(0);
    }

    _stakeTokens = async (evt) => {
        evt.preventDefault();
        this.setState({ lblLoadingAll: "Transaction in progress" });
        const amount = this.valTokenStake.value.toString();
        const resp = await this.props.stakeTokens(amount);
        this.setState({ lblLoadingAll: "Stake Token " + resp.txt });
        const elmJml = AutoNumeric.getAutoNumericElement(this.valTokenStake)
        elmJml.set(0);
        this.resetLoadingAll();
    }
    _unstakeTokens = async (evt) => {
        evt.preventDefault();
        this.setState({ lblLoadingAll: "Transaction in progress" });
        const amount = this.valTokenWithdraw.value.toString();
        const resp = await this.props.unstakeTokens(amount);
        this.setState({ lblLoadingAll: "Withdraw Token " + resp.txt });
        const elmJml = AutoNumeric.getAutoNumericElement(this.valTokenWithdraw)
        elmJml.set(0);
        this.resetLoadingAll();
    }
    _distriReward = async (evt) => {
        evt.preventDefault();
        this.setState({ lblLoadingAll: "Transaction in progress" });
        const resp = await this.props.distriReward();
        this.setState({ lblLoadingAll: "Distribute Token " + resp.txt });
        this.resetLoadingAll();
    }
    _unstakeReward = async (evt) => {
        evt.preventDefault();
        if(this.props.rwdBalance <= 0){
            alert("Rewards Balance kosong");
            return false;
        }
        this.setState({ lblLoadingAll: "Transaction in progress" });
        const resp = await this.props.unstakeReward();
        this.setState({ lblLoadingAll: "Distribute Token " + resp.txt });
        this.resetLoadingAll();
    }
    _stakeReward = async (evt) => {
        evt.preventDefault();
        if(this.props.rwdBalance <= 0){
            alert("Rewards Balance kosong");
            return false;
        }
        this.setState({ lblLoadingAll: "Transaction in progress" });
        const resp = await this.props.stakeReward();
        this.setState({ lblLoadingAll: "Distribute Token " + resp.txt });
        this.resetLoadingAll();
    }
    render() {
        const { items } = this.state;

        let divTranfer;
        if (this.props.akun == this.props.akunOwner) {
            divTranfer = <div>
                <label className='text-info'>Transfer Token ke nasabah</label>
                <form onSubmit={evt => this._transferToken(evt)}>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <td>Address</td>
                                <td>Token</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type="text"
                                        name='valAddress'
                                        value={this.state.valAddress || ""}
                                        required
                                        placeholder='0x0'
                                        className='form-control'
                                        onChange={this.onInputchange}
                                    />
                                    <button type='submit' className='btn btn-danger'>Transfer</button>
                                </td>
                                <td>
                                    <div className='input-group mb-4'>
                                        <input
                                            className='autoNumber'
                                            required
                                            placeholder='0'
                                            ref={(ev) => this.valTokenTrf = ev}
                                            max={this.props.tetherBalance}
                                            onKeyUp={evt => this.updateAutoNumeric(evt)}
                                        />
                                        <div className='input-group-open'>
                                            <div className='input-group-text'>
                                                {this.props.currency}
                                            </div>
                                        </div>
                                    </div>
                                    <label className='text-danger text-bolder'>{this.state.lblLoading}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div className='card-body text-center'>
                    <button onClick={evt => this._distriReward(evt)}>Reward Tokens</button>
                </div>
            </div>;
        }

        // console.log(this.props);

        return (
            <div id='content' className='mt-3'>
                {divTranfer}
                <hr />
                <table className='table text-muted text-center'>
                    <thead>
                        <tr>
                            <th scope='col' style={{ width: "50%" }}>Staking Balance</th>
                            <th scope='col'>Reward Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{addCommas(this.props.stakingBalance)} {this.props.currency}</td>
                            <td>
                                {addCommas(this.props.rwdBalance)} {this.props.currency}
                                <button className=' btn btn-xs btn-warning' type='button' style={{ float: "right" }} onClick={evt => this._unstakeReward(evt)}>WITHDRAW</button>
                                <div style={{ float: "right" }}>
                                    <button className=' btn btn-xs btn-primary' type='button' onClick={evt => this._stakeReward(evt)}>Stake</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h1 className='text-danger'>{this.state.lblLoadingAll}</h1>
                <div className='card mb-2'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <form onSubmit={event => this._stakeTokens(event)}
                                className='mb-3'>
                                <div>
                                    <label className='float-left'>Input Stake Tokens value</label>
                                    <div className='input-group mb-4'>
                                        <input
                                            className='autoNumber'
                                            max={this.props.tetherBalance}
                                            ref={(inp) => { this.valTokenStake = inp }}
                                            type='text'
                                            placeholder='0'
                                            required
                                            onKeyUp={evt => this.updateAutoNumeric(evt)}
                                        />
                                        <div className='input-group-open'>
                                            <div className='input-group-text'>
                                                {this.props.currency}
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-md btn-info' type='submit'>DEPOSIT</button>
                                </div>
                            </form>
                        </div>
                        <div className='col-sm-6'>
                            <form onSubmit={evt => this._unstakeTokens(evt)}
                                className='mb-3'>
                                <div>
                                    <span className='float-right'>
                                        Balance: <b>{addCommas(this.props.tetherBalance)}</b>
                                    </span>
                                    <div className='input-group mb-4'>
                                        <input
                                            className='autoNumber'
                                            max={this.props.stakingBalance}
                                            ref={(inp) => { this.valTokenWithdraw = inp }}
                                            type='text'
                                            placeholder='0' required
                                            onKeyUp={evt => this.updateAutoNumeric(evt)}
                                        />
                                        <div className='input-group-open'>
                                            <div className='input-group-text'>
                                                {this.props.currency}
                                            </div>
                                        </div>
                                    </div>
                                    <button className='btn btn-md btn-success' type='submit'>WITHDRAW</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    TOTAL STAKE : {addCommas(this.props.totalStakes)}<br />
                    TOTAL STAKEHOLDER : {this.props.totalStakeholder}
                </div>
            </div >
        )
    }
}

function addCommas(nStr) {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;

    // return (nStr + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default Main;