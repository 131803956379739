import React, { Component } from 'react';
import './App.css';
import web3 from './web3';
import NusaToken from './nusa';
import Navbar from './Navbar';
import Main from './Main';

let intervalId = null;
let nusaToken = null;
let account;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      akun: '0x0',
      tetherBalance: '0',
      rwdBalance: '0',
      stakingBalance: '0',
      loading: true,
      currency: '-',
      akunOwner: '0x0',
      totalStakes: 0,
      totalStakeholder: 0,
    }

  }
  async componentDidMount() {
    await this.loadWeb3();
  }
  componentWillUnmount() {
    clearInterval(intervalId);
    intervalId = null;
    this.setState({ akun: "0x0", akunOwner: "0x0", loading: true });

  }
  async loadWeb3() {
    if (web3 !== null) {
      account = await web3.eth.getAccounts();
      if (account.length > 0) {
        const chainID = await web3.eth.net.getId();
        this.startInterval();
        if (chainID != NusaToken.chainID) {
          this.componentWillUnmount();
        } else {
          this.setState({ akun: account[0] });
          this.loadBlockchain();
        }
        // console.log(chainID, NusaToken.chainID);
      } else {
        this.componentWillUnmount();
      }
    }
  }
  async loadBlockchain() {
    if (nusaToken === null) {
      nusaToken = new web3.eth.Contract(NusaToken.abi, NusaToken.address);
    }
    if (nusaToken !== null && Object.keys(nusaToken).length !== 0) {
      const akun = account[0];
      const akunOwner = await nusaToken.methods.owner().call();
      const tetherBalance = await nusaToken.methods.balanceOf(akun).call();
      const rwdBalance = await nusaToken.methods.rewardOf(akun).call();
      const stakingBalance = await nusaToken.methods.stakeOf(akun).call();
      const currency = await nusaToken.methods.symbol().call();
      const totalStakes = await nusaToken.methods.totalStakes().call();
      const totalStakeholder = await nusaToken.methods.getLenStakeholder().call();

      this.setState({
        tetherBalance: this.tokenFromWei(tetherBalance.toString()),
        rwdBalance: this.tokenFromWei(rwdBalance.toString()),
        stakingBalance: this.tokenFromWei(stakingBalance.toString()),
        akunOwner: akunOwner,
        currency: currency,
        totalStakes: this.tokenFromWei(totalStakes),
        totalStakeholder: totalStakeholder
      });
      this.setState({ loading: false });
    }
  }
  tokenToWei(_params) {
    return web3.utils.toWei(_params, 'ether');
  }
  tokenFromWei(_params) {
    return web3.utils.fromWei(_params, 'ether');
  }

  //kumpulan fungsi
  startInterval() {
    if (intervalId == null) {
      intervalId = setInterval((ths) => {
        this.loadWeb3();
      }, 5000);
    }
    console.log("RUNNING", intervalId)
  }
  connectWalet = async () => {
    const chainID = await web3.eth.net.getId();
    console.log(NusaToken.chainID, chainID);
    if (chainID != NusaToken.chainID) {
      await this.changeWalet();
    } else {
      try {
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        this.loadWeb3();
      } catch (error) {
        alert("error connect to wallet");
      }
    }

  }
  changeWalet = async () => {
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(NusaToken.chainID).toString(16)}` }], // chainId must be in hexadecimal numbers
      });

      await this.connectWalet();
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      console.log("error.code", error.code)
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: NusaToken.newChain,
          });
          await this.connectWalet();
        } catch (addError) {
          console.error(addError);
          alert('gagal tambahkan jaringan')
        }
      } else {
        alert('gagal beralih jaringan')
      }
      console.error(error);
    }
  }
  trfTokenToCust = async (_addr, _jml) => {
    let resp = {};
    let jml = this.tokenToWei(_jml);
    try {
      await nusaToken.methods.transfer(_addr, jml)
        .send({ from: this.state.akunOwner })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }
  stakeTokens = async (_jml) => {
    let resp = {};
    let jml = this.tokenToWei(_jml);
    try {
      await nusaToken.methods.createStake(jml)
        .send({ from: this.state.akun })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }
  unstakeTokens = async (_jml) => {
    let resp = {};
    let jml = this.tokenToWei(_jml);
    try {
      await nusaToken.methods.removeStake(jml)
        .send({ from: this.state.akun })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }
  distriReward = async () => {
    let resp = {};
    try {
      await nusaToken.methods.distributeRewards()
        .send({ from: this.state.akun })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }
  unstakeReward = async () => {
    let resp = {}
    try {
      await nusaToken.methods.withdrawReward()
        .send({ from: this.state.akun })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }
  stakeReward = async () => {
    let resp = {}
    try {
      await nusaToken.methods.stakeReward()
        .send({ from: this.state.akun })
        .on('transactionHash', async (hash) => {
          await this.loadBlockchain();
          resp.txt = "Berhasil";
        });
    } catch (error) {
      resp.txt = "Gagal";
    }
    return resp;
  }


  // react code in here
  render() {
    let content;
    {
      this.state.loading ? content = <p id='loader' className='text-center'>LOADING PLEASE</p> :
        content = <Main
          tetherBalance={this.state.tetherBalance}
          rwdBalance={this.state.rwdBalance}
          stakingBalance={this.state.stakingBalance}
          stakeTokens={this.stakeTokens}
          distriReward={this.distriReward}
          unstakeTokens={this.unstakeTokens}
          unstakeReward={this.unstakeReward}
          currency={this.state.currency}
          trfTokenToCust={this.trfTokenToCust}
          akun={this.state.akun}
          akunOwner={this.state.akunOwner}
          totalStakes={this.state.totalStakes}
          totalStakeholder={this.state.totalStakeholder}
        />
    };
    return (
      <div>
        <Navbar
          connectWalet={this.connectWalet}
          akun={this.state.akun}
          akunOwner={this.state.akunOwner}
        />
        <div className='container-fluid mt-5'>
          <div className='row'>
            <main role='main' className='col-lg-12'>
              <div>
                {content}
              </div>
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export default App;